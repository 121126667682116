
import {
  defineComponent, toRefs, computed
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    brandLength: {
      type: Number,
      required: true,
    },
    campaignLength: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { brandLength, campaignLength } = toRefs(props)

    const buttonLabel = computed(() => {
      if (!brandLength.value && !campaignLength.value) return 'Register brand and campaign'
      if (!brandLength.value) return 'Register brand'
      return 'Register campaign'
    })

    return {
      buttonLabel,
    }
  },
})
